import { Button, Dropdown, MenuProps, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { history, useModel, useRequest } from "@@/exports";
import { getDictData, listProgress, } from "@/services/common/api";
import { PlusCircleOutlined } from "@ant-design/icons";
import { createIssue, createReview, delIssue, listCountryMatrix, updIssue } from "@/services/rd/issues/api";
import IssueModalForm from "@/pages/RD/ProductIssue/components/IssueModalForm";
import { useAccess, Access } from 'umi';
import SprintModalForm from "@/pages/RD/Sprint/components/SprintModalForm";
import { createOrUpdSprint } from "@/services/rd/sprint/api";
import TaskModalForm from "@/pages/RD/TaskCenter/components/TaskModalForm";
import { createTask } from "@/services/rd/task/api";
import IssueBusinessModalForm from "@/pages/RD/BusinessIssue/components/IssueBusinessModalForm";
import { createBusinessIssue, delBusinessIssue, updBusinessIssue } from "@/services/rd/businessIssue/api";
import { selectListV2 } from "@/services/settings/label";
import EditReviewDrawer from "@/pages/RD/Review/components/EditReviewDrawer";



const App: React.FC = () => {
  const [type, setType] = useState<any>('');
  const [modalOpen, handleModalOpen] = useState<boolean>(false);
  const [businessModalOpen, handleBusinessModalOpen] = useState<boolean>(false);
  const [sprintModalOpen, handleSprintModalOpen] = useState<boolean>(false);
  const [taskModalOpen, handleTaskModalOpen] = useState<boolean>(false);
  const [reviewModalOpen, handleReviewModalOpen] = useState<boolean>(false);
  const { initialState, setInitialState } = useModel('@@initialState');
  // const { data: userList, run: runUserList } = useRequest(listUsers, { manual: false })
  const { data: complexRateTypeList, run: runComplexRateTypeList } = useRequest(() => {
    return getDictData('complex_rate_type')
  }, { manual: true })
  const { data: priorityLevelList, run: runPriorityLevelList } = useRequest(() => {
    return getDictData('priority_level')
  }, { manual: true })
  const { data: countryList, run: runCountryList } = useRequest(() => {
    return listCountryMatrix()
  }, { manual: true })
  const { data: rdTaskType, run: runRdTaskType } = useRequest(() => {
    return getDictData('rd_task_type')
  }, { manual: true })
  const { data: rdTaskProgress, run: runRdTaskProgress } = useRequest(() => {
    return listProgress({ progressType: 'rd_task_progress' })
  }, { manual: true })

  // 查任务标签
  const { data: taskLabels, run: runTaskLabels } = useRequest(() => {
    return selectListV2("2")
  }, { manual: true })


  const items: MenuProps['items'] = [
    !useAccess().normalRolesFilter(['Product Manager']) && {
      label: '创建业务需求',
      key: 'businessIssue',
    },
    useAccess().normalRolesFilter(['Product Manager']) && {
      label: '创建产品需求',
      key: 'productIssue',
    },
    useAccess().normalRolesFilter(['Product Manager', 'PM']) && {
      label: '创建Sprint',
      key: 'sprint',
    },
    useAccess().normalRolesFilter(['qa', 'ux', 'frontend', 'backend', 'de', 'devops']) && {
      label: '创建任务',
      key: 'task',
    },
    {
      label: '创建评审',
      key: 'review',
    },
    useAccess().normalRolesFilter(['qa']) && {
      label: '创建缺陷',
      key: 'testBug',
    },
    {
      label: '创建线上问题',
      key: 'onlineBug',
    },
  ];

  useEffect(() => {
    if (modalOpen || sprintModalOpen || taskModalOpen) {
      runComplexRateTypeList()
      runCountryList()
      runPriorityLevelList()
      runRdTaskProgress()
      runRdTaskType()
      runTaskLabels()
    }

  }, [modalOpen, sprintModalOpen, taskModalOpen]);



  return (
    <>
      {/* <Access accessible={useAccess().normalRolesFilter(['Product Manager', 'qa', 'ux', 'frontend', 'backend', 'de', 'devops'])}> */}
      <Tooltip title='按类型新建'>
        <Dropdown menu={{
          items: items, onClick: (value) => {
            console.log(value)
            switch (value.key) {
              case 'businessIssue': handleBusinessModalOpen(true); break;
              case 'productIssue': history.push('/rd/productIssue/createOrUpd/0'); break;
              case 'sprint': handleSprintModalOpen(true); break;
              case 'task': handleTaskModalOpen(true); break;
              case 'review': handleReviewModalOpen(true); break;
              case 'onlineBug': history.push('/rd/onlineBug/create'); break;
              case 'testBug': history.push('/rd/test/bug/create'); break;
            }
          }
        }} trigger={['click']}>
          <Button
            size='middle'
            type="text"
            key="primary"
            style={{
              // boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.2)',
              color: '#00c479'
            }}
            onClick={(e) => {
              e.preventDefault()

            }}
            icon={<PlusCircleOutlined />}
          />
        </Dropdown>
      </Tooltip>
      {/* </Access > */}
      <IssueBusinessModalForm
        detail={null}
        isCreated={true}
        onSubmit={async (value) => {
          let resp;
          console.log(value);
          if (!value.staging) {
            resp = await createBusinessIssue(value);
          } else if (value.staging) {
            await delBusinessIssue(value.id);
            value.id = '';
            resp = await createBusinessIssue(value);
          }
          if (resp.status === 'success') {
            handleBusinessModalOpen(false);
            history.push('/rd/businessIssue')
            window.dispatchEvent(new Event('businessIssueUpdated'));
          }
        }}
        onCancel={() => {
          handleBusinessModalOpen(false);
        }}
        updateModalOpen={businessModalOpen} complexRateTypeList={complexRateTypeList} priorityLevelList={priorityLevelList} countryList={countryList}      // values={currentRow || {}}
      />
      {/* <IssueModalForm
        detail={null}
        isCreated={true}
        onSubmit={async (value: any) => {
          let resp;
          if (!value.staging) {
            resp = await createIssue(value);
          } else {
            await delIssue(value.id);
            resp = await createIssue(value);
          }
          if (resp.status === 'success') {
            handleModalOpen(false);
            history.push('/rd/productIssue')
            window.dispatchEvent(new Event('issueUpdated'));
          }
        }}
        onCancel={() => {
          handleModalOpen(false);
        }}
        updateModalOpen={modalOpen} complexRateTypeList={complexRateTypeList} priorityLevelList={priorityLevelList} countryList={countryList}
        productManagerCodes={[initialState?.currentUser?.userName]}
        systemId={initialState?.defaultSystem?.systemDTO?.systemId !== 'all' ? initialState?.defaultSystem?.systemDTO?.systemId : null}
      /> */}
      <SprintModalForm
        detail={null}
        isCreated={true}
        onSubmit={async (value) => {
          value.startDate = value.time[0];
          value.endDate = value.time[1];
          value.planId = '';
          let resp = await createOrUpdSprint(value);
          if (resp.status === 'success') {
            handleSprintModalOpen(false);
            history.push('/rd/sprint/table')
            window.dispatchEvent(new Event('sprintUpdated'));
          }
        }}
        onCancel={() => {
          handleSprintModalOpen(false);
        }}
        updateModalOpen={sprintModalOpen}
      // values={currentRow || {}}
      />
      <TaskModalForm
        detail={null}
        isCreated={true}
        onSubmit={async (value) => {
          let resp: any;
          resp = await createTask(value);

          if (resp.status === 'success') {
            handleTaskModalOpen(false);
            history.push('/rd/tasks')
            window.dispatchEvent(new Event('taskUpdated'));
          }
        }}
        onCancel={() => {
          handleTaskModalOpen(false);
        }}
        updateModalOpen={taskModalOpen} isStash={false} complexRateTypeList={complexRateTypeList} priorityLevelList={priorityLevelList} // values={currentRow || {}}
        rdTaskType={rdTaskType} rdTaskProgress={rdTaskProgress} taskLabels={taskLabels} />

      <EditReviewDrawer
        relationIds={[]}
        isDisabled={false}
        detail={null}
        isCreated={true}
        onSubmit={async (value) => {
          handleReviewModalOpen(false);
          history.push('/rd/review')
          window.dispatchEvent(new Event('reviewUpdated'));
        }}
        onCancel={() => {
          handleReviewModalOpen(false);
        }}
        reviewType={'REQUIREMENT_REVIEW'}
        systemIdList={initialState?.defaultSystem?.systemDTO?.systemId === 'all' ? [] : [initialState?.defaultSystem?.systemDTO?.systemId]}
        updateModalOpen={reviewModalOpen}
      />

    </>
  );
};

export default App;
