// @ts-ignore
/* eslint-disable */
import { request } from '@umijs/max';






/** 获取详情 */
export async function getBusinessIssueDetail(id: string,
  options?: { [key: string]: any },
) {
  return request<API.ImileResponse<API.BusinessIssueDetail>>('/business-issue/detail?id=' + id, {
    method: 'GET',
    ...(options || {}),
  });
}

/** 删除 */
export async function delBusinessIssue(id: string,
  options?: { [key: string]: any },
) {
  return request<API.ImileResponse<any>>('/business-issue/delete?id=' + id, {
    method: 'GET',
    ...(options || {}),
  });
}
/** 关闭*/
export async function closeBusinessIssue(id: string,
  options?: { [key: string]: any },
) {
  return request<API.ImileResponse<any>>('/business-issue/close?id=' + id, {
    method: 'GET',
    ...(options || {}),
  });
}
/** 拒绝验收*/
export async function rejectBusinessIssue(
  body: {},
  options?: { [key: string]: any },
) {
  return request<API.ImileResponse<any>>('/business-issue/reject-accept', {
    method: 'POST',
    data: {
      ...body
    },
    ...(options || {}),
  });
}

/** 恢复 */
export async function restoreBusinessIssue(id: string,
  options?: { [key: string]: any },
) {
  return request<API.ImileResponse<any>>('/business-issue/restore?id=' + id, {
    method: 'GET',
    ...(options || {}),
  });
}

/** 查业务需求的产品需求 */
export async function listIssueByBusiness(id: string,
  options?: { [key: string]: any },
) {
  return request<API.ImileResponse<any>>('/business-issue/issue-detail?id=' + id, {
    method: 'GET',
    ...(options || {}),
  });
}


/**
 * 创建
 * @param body 
 * @param options 
 * @returns 
 */

export async function createBusinessIssue(
  body: {},
  options?: { [key: string]: any },
) {
  return request<API.ImileResponse<any>>('/business-issue/create', {
    method: 'POST',
    data: {
      ...body
    },
    ...(options || {}),
  });
}

/**
 * 
 * @param body 修改
 * @param options 
 * @returns 
 */
export async function updBusinessIssue(
  body: {},
  options?: { [key: string]: any },
) {
  return request<API.ImileResponse<any>>('/business-issue/update', {
    method: 'POST',
    data: {
      ...body
    },
    ...(options || {}),
  });
}
/**
 * 获取任务明细(查指定需求或规划或...下的任务)
 * @param body 
 * * 查询类型
 * 需求：queryType = issue
 * 规划：queryType = plan
 * *
 * 查询id
 * 需求：queryId = 需求id
 * 规划：queryId = 规划id
 * @param options 
 * @returns 
 */
export async function listParticulars(
  body: {
    queryType: string,
    queryId: string,
  },
  options?: { [key: string]: any },
) {
  return request<API.ImileResponse<any>>('/rd-task/particulars', {
    method: 'POST',
    data: {
      ...body
    },
    ...(options || {}),
  });
}
export async function bindProductIssue(
  body: any,
  options?: { [key: string]: any },
) {
  return request<API.ImileResponse<any>>('/business-issue/bind-product-issue', {
    method: 'POST',
    data: {
      ...body
    },
    ...(options || {}),
  });
}
export async function bindProductManagement(
  body: any,
  options?: { [key: string]: any },
) {
  return request<API.ImileResponse<any>>('/business-issue/bind-product-management', {
    method: 'POST',
    data: {
      ...body
    },
    ...(options || {}),
  });
}

export async function deleteProductIssue(
  body: any,
  options?: { [key: string]: any },
) {
  return request<API.ImileResponse<any>>('/business-issue/delete-product-issue', {
    method: 'POST',
    data: {
      ...body
    },
    ...(options || {}),
  });
}

/** 需求列表 */
export async function listBindIssues(id: string) {
  return request<API.ImileResponse<API.IssueListItem[]>>('/business-issue/issue-detail?id=' + id, {
    method: 'GET',
  });
}

// 分页查询
export async function pagedBusinessIssues(
  params: {
    current?: number;
    pageSize?: number;
  },
  options?: { [key: string]: any },
) {
  return request<API.ImileResponse<API.BusinessIssueListItem>>('/business-issue/page', {
    method: 'POST',
    data: {
      currentPage: params.current,
      showCount: params.pageSize,
      ...params
    },
    ...(options || {}),
  });
}
/** 获取暂存需求 */
export async function getRelatedBusinessIssue(issueId: string, options?: { [key: string]: any }) {
  return request<API.ImileResponse<API.BusinessIssueDetail>>('/issue-v2/related/business-issue?issueId=' + issueId, {
    method: 'GET',
    ...(options || {}),
  });
}
/** 获取暂存需求 */
export async function getStagingBusinessIssueDetail(options?: { [key: string]: any }) {
  return request<API.ImileResponse<API.BusinessIssueDetail>>('/business-issue/user/staging-issue-detail', {
    method: 'GET',
    ...(options || {}),
  });
}
/** 获取产品经理 */
export async function getProductManager(options?: { [key: string]: any }) {
  return request<API.ImileResponse<API.BusinessIssueDetail>>('/system/user/role?roleKey=Product Manager', {
    method: 'GET',
    ...(options || {}),
  });
}
/** 获取模版 */
export async function getTemplate(templateType: string, ownerCode: string, options?: { [key: string]: any }) {
  return request<API.ImileResponse<API.BusinessIssueDetail>>('/content-template/get-template', {
    method: 'GET',
    params: {
      templateType: templateType,
      ownerCode: ownerCode
    },
    ...(options || {}),
  });
}

export async function searchCnt(searchName: string, options?: { [key: string]: any }) {
  return request<API.ImileResponse<any>>('/business-issue/search-cnt', {
    method: 'GET',
    params: {
      searchName: searchName
    },
    ...(options || {}),
  });
}

export async function dismissed(
  body: any,
  options?: { [key: string]: any },
) {
  return request<API.ImileResponse<any>>('/business-issue/dismissed', {
    method: 'POST',
    data: {
      ...body
    },
    ...(options || {}),
  });
}
/**获取所有模版 */
export async function pageIssueTemplate(body?: any, options?: { [key: string]: any }) {
  return request<API.ImileResponse<any>>('/content-template/page', {
    method: 'POST',
    data: {
      currentPage: body?.current,
      showCount: body?.pageSize,
      ...body,
    },
    ...(options || {}),
  });
}
/** 删除模版 */
export async function delIssueTemplate(id: string, options?: { [key: string]: any }) {
  return request<API.ImileResponse<any>>('/content-template/delete?id=' + id, {
    method: 'GET',
    ...(options || {}),
  });
}
/** 更新模版 */
export async function updIssueTemplate(body: any, options?: { [key: string]: any }) {
  return request<API.ImileResponse<any>>('/content-template/update', {
    method: 'POST',
    data: {
      ...body
    },
    ...(options || {}),
  });
}
/** 保存模版 */
export async function saveIssueTemplate(body: any, options?: { [key: string]: any }) {
  return request<API.ImileResponse<any>>('/content-template/save', {
    method: 'POST',
    data: {
      ...body
    },
    ...(options || {}),
  });
}

/** 更新模版状态 */
export async function changeTemplateStatus(body: any, options?: { [key: string]: any }) {
  return request<API.ImileResponse<any>>('/content-template/switch-status', {
    method: 'POST',
    data: {
      ...body
    },
    ...(options || {}),
  });
}
/** 获取模版详情*/
export async function getTemplateDetail(id: string, options?: { [key: string]: any }) {
  return request<API.ImileResponse<any>>('/content-template/detail?id=' + id, {
    method: 'GET',
    ...(options || {}),
  });
}

