/**
 * @see https://umijs.org/zh-CN/plugins/plugin-access
 * */
export default function access(initialState: { currentUser?: API.CurrentUser, roles?: [], permissions?: [], schedulingUser: object } | undefined) {
  const { currentUser, roles, permissions, schedulingUser } = initialState ?? {};
  return {
    //值班人
    canScheduling: currentUser && schedulingUser && currentUser.userName === schedulingUser.userCode,
    normalRolesFilter: (role) => roles?.some(val => role.includes(val)), // initialState 中包含了的路由才有权限访问
    canAdmin: roles?.includes('admin'),
    canSls: roles?.includes('sls'),
    canWebLog: roles?.includes('webLog'),
    canLogCenter: roles?.includes('logCenter'),
    canDevelopLog: roles?.includes('developLog'),
    canCS: roles?.includes('clickVisual'),
    canTool: roles?.some(val => ['sls', 'clickVisual', 'polarDB'].includes(val)),
    canCto: roles?.includes('CTO'),
    canDevops: roles?.includes('devops'),
    canLeader: roles?.includes('team_leader'),
    canGrayReleaseConfig: roles?.includes('gray_release_config'),
    canK8sConfig: roles?.includes('devops'), // k8s集群配置权限
    // canDelivery: roles?.includes('qa'),
  };
}
