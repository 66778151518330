import {
  ModalForm,
  ProFormDateTimePicker,
  ProFormGroup,
  ProFormInstance,
  ProFormRadio,
  ProFormSelect,
  ProFormText,
  ProFormTextArea,
  ProFormSwitch,
  ProForm,
} from '@ant-design/pro-components';
import { useIntl } from '@umijs/max';

import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, Form, Row, Tooltip, message, Drawer, Card, Space } from 'antd';

import 'braft-editor/dist/index.css';
import { listUsers, listChangeSystem, getDictData, uploadFile } from '@/services/common/api';
import { useModel, useRequest } from '@@/exports';
import moment from 'moment';

import { listIssueBasicInfo, listSelectIssue, createReview, updReview } from '@/services/rd/issues/api';
import { listPlanBySystemIds, listPlanByIds, getExecutors } from '@/services/rd/sprint/api';
import { listCaseBySystemIds, listCaseByIds } from '@/services/rd/test/api';


import MeetingRoomTable from '@/pages/RD/Review/components/MeetingRoomTable';
import e from 'express';

export type FormValueType = {
  reviewId?: string;
  reviewType?: string;
  systemId?: string;
  isFinish?: any;
  time?: string;
  frequency?: string;
  relationIds?: any;
} & Partial<API.RuleListItem>;

export type UpdateFormProps = {
  onCancel: (flag?: boolean, formVals?: FormValueType) => void;
  onSubmit: (values: FormValueType) => Promise<void>;
  updateModalOpen: boolean;
  isCreated: boolean;
  isCopy: boolean;
  detail: any;
  relationIds: any;
  isDisabled: boolean;
  systemIdList: any;
  reviewType: any;
  reviewName: any;
  /**区分sprint中的创建评审 */
  type?: string;
  /**评审参与人 */
  relationUsers?: string[];
};

const EditReviewDrawer: React.FC<UpdateFormProps> = (props) => {
  const { initialState, setInitialState } = useModel('@@initialState');
  const issueReviewRef = useRef<ProFormInstance>();
  const [checked, setChecked] = useState<boolean>(false);
  const [haveMeeting, setHaveMeeting] = useState<boolean>(true);
  const [hidenRelation, setHidenRelation] = useState<boolean>(false);
  const intl = useIntl();
  const { data: userList, run: runUserList } = useRequest(listUsers, { manual: true });
  const [formValues, setFormValues] = useState<FormValueType>({});
  const [meetingRoomName, setMeetingRoomName] = useState();
  const [meetingRoomIds, setMeetingRoomIds] = useState<[]>([]);
  const [handleRoomsOpen, setHandleRoomsOpen] = useState<boolean>(false);
  const [reviewStartTime, setReviewStartTime] = useState();
  const [reviewEndTime, setReviewEndTime] = useState();
  const [executorCodes, setExecutorCodes] = useState<[]>([]);
  const [meetingRepeatType, setMeetingRepeatType] = useState<string>('-1'); // 会议重复类型
  const executorCodesRef = useRef([]);
  const [reloadTableId, setReloadTableId] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const issueOfReviewTypes = [
    'REQUIREMENT_REVIEW',
    'TECHNICAL_SCHEME_REVIEW',
    'DESIGN_DRAFT_REVIEW',
  ];
  const havaRelationReviewTypes = [
    'REQUIREMENT_REVIEW',
    'TECHNICAL_SCHEME_REVIEW',
    'DESIGN_DRAFT_REVIEW',
    'SPRINT_REVIEW',
    'TEST_CASE_REVIEW',
  ];

  const { data: selectIssueList, run: runSelectIssueList } = useRequest(
    (param) => {
      return listSelectIssue({
        ...param,
      });
    },
    { manual: true },
  );

  // 查系统下拉列表
  const { data: systemList, run: runSystemList } = useRequest(() => listChangeSystem(), {
    manual: false,
  });

  // 查评审类型
  const { data: reviewTypeList, run: runReviewTypeList } = useRequest(
    () => {
      return getDictData('review_type');
    },
    { manual: false },
  );

  // 根据系统查规划下拉列表
  const { data: selectPlanList, run: runSelectPlanList } = useRequest(
    (param) => {
      return listPlanBySystemIds(param);
    },
    { manual: true },
  );

  // 根据系统查测试用例下拉列表
  const { data: selectTestCaseList, run: runSelectTestCaseList } = useRequest(
    (param) => {
      return listCaseBySystemIds(param);
    },
    { manual: true },
  );

  // 获取记录执行人
  const { run: runExecutorInfo } = useRequest(
    (relatedType: string, relatedIds: any) => {
      const body = {
        relatedType: relatedType,
        relatedIds: relatedIds,
      };
      return getExecutors(body);
    },
    {
      manual: true,
      onSuccess: (res) => {
        setExecutorCodes(res?.executorCodes);
        executorCodesRef.current = res?.executorCodes;
        issueReviewRef.current?.setFieldValue('inviteesCodes', res?.executorCodes);
      },
    },
  );

  const setFields = () => {
    setChecked(props.detail?.isFinish);
    issueReviewRef.current?.setFieldsValue({
      reviewName: props.detail?.reviewName || '',
      reviewType: props.detail?.reviewType || '',
      reviewDate: props.detail?.reviewDate || null,
      description: props.detail?.description || '',
      inviteesCodes: props.detail?.inviteesCodes || [],
      isFinish: props.detail?.isFinish || false,
      systemIdList: props.detail?.systemIdList || [],
      uploadAttachments: props.detail?.uploadAttachments || [],
      meetingRoomId: props.detail?.meetingRoomId || null,
      deputyRoomIds: props.detail?.deputyRoomIds || [],
      meetingRepeatType: props.detail?.meetingRepeatType || '-1',
    });
    setMeetingRepeatType(props.detail?.meetingRepeatType || '-1');
    if (issueOfReviewTypes.includes(props.detail?.reviewType)) {
      if (props.detail?.systemIdList?.length > 0) {
        runSelectIssueList({
          systemIdList: props.detail?.systemIdList,
          issueIds: props.detail?.relationIds,
        });
      }
      issueReviewRef.current?.setFieldValue('issueIds', props.detail?.relationIds);
    }

    if (props.detail?.reviewType === 'SPRINT_REVIEW') {
      if (props.detail?.systemIdList?.length > 0) {
        runSelectPlanList({
          systemIds: props.detail?.systemIdList,
          ids: props.detail?.relationIds,
        });
      }
      issueReviewRef.current?.setFieldValue('planIds', props.detail?.relationIds);
    }

    if (props.detail?.reviewType === 'TEST_CASE_REVIEW') {
      if (props.detail?.systemIdList?.length > 0) {
        runSelectTestCaseList({
          systemIds: props.detail?.systemIdList,
          ids: props.detail?.relationIds,
        });
      }
      issueReviewRef.current?.setFieldValue('testCaseIds', props.detail?.relationIds);
    }

    setMeetingRoomIds(props?.detail?.allMeetingRoomIds);
    setMeetingRoomName(props?.detail?.meetingRoom);

    if (props?.detail?.reviewDate) {
      const startStamp = moment(props?.detail?.reviewDate).unix();
      const endStamp = parseInt(startStamp) + parseInt(props?.detail?.meetingDuration || 1800);
      setReviewStartTime(startStamp);
      setReviewEndTime(endStamp);
    }
  }

  const initFields = () => {
    setChecked(false);
    issueReviewRef.current?.setFieldsValue({
      reviewName: props?.reviewName || '',
      reviewType: props?.reviewType || '',
      reviewDate: null,
      description: '',
      inviteesCodes: props?.relationUsers || [],
      isFinish: false,
      systemIdList: props?.systemIdList || [],
      uploadAttachments: [],
      meetingRoomId: null,
      deputyRoomIds: [],
      meetingRepeatType: '-1',
      issueIds: [],
      planIds: [],
      testCaseIds: []
    });
    setMeetingRepeatType('-1');
    setMeetingRoomIds([]);
    setMeetingRoomName(null)
    setReviewStartTime(null);
    setReviewEndTime(null);
  };


  // 系统变更时，刷新各个下拉数据
  const changeSystem = async () => {
    // 获取系统id集合
    const systemIds =
      issueReviewRef?.current?.getFieldValue('systemIdList')?.length > 0
        ? issueReviewRef?.current?.getFieldValue('systemIdList')
        : ['null'];

    // 获取评审类型
    const reviewType = issueReviewRef?.current?.getFieldValue('reviewType');

    const issueIds =
      reviewType === issueOfReviewTypes.includes(reviewType)
        ? issueReviewRef.current?.getFieldValue('issueIds')
        : [];
    const planIds =
      reviewType === 'SPRINT_REVIEW' ? issueReviewRef.current?.getFieldValue('planIds') : [];
    const testCaseIds =
      reviewType === 'TEST_CASE_REVIEW' ? issueReviewRef.current?.getFieldValue('testCaseIds') : [];

    // 根据系统id过滤选择的需求
    if (issueIds?.length > 0) {
      // 查选中需求的基础信息
      const selectIssueParam = {
        issueIdList: issueIds,
      };
      const { resultObject } = await listIssueBasicInfo(selectIssueParam);

      // 用系统过滤下拉框选中的需求并赋值
      const validIssueIds = resultObject
        ?.filter((u) => systemIds.includes(u.systemId))
        ?.map((i) => i.id);
      issueReviewRef.current?.setFieldValue('issueIds', validIssueIds);
    }

    // 根据系统id过滤选择的规划
    if (planIds?.length > 0) {
      // 查选中规划的基础信息
      const selectParam = {
        ids: planIds,
        systemIds: systemIds,
      };
      const { resultObject } = await listPlanByIds(selectParam);

      // 用系统过滤下拉框选中的规划并赋值
      const validIds = resultObject?.map((i) => i.id);
      issueReviewRef.current?.setFieldValue('planIds', validIds);
    }

    // 根据系统id过滤选择的用例
    if (testCaseIds?.length > 0) {
      // 查选中用例的基础信息
      const selectParam = {
        ids: testCaseIds,
      };
      const { resultObject } = await listCaseByIds(selectParam);

      // 用系统过滤下拉框选中的用例并赋值
      const validIds = resultObject
        ?.filter((u) => systemIds.includes(u.systemId))
        ?.map((i) => i.id);
      issueReviewRef.current?.setFieldValue('testCaseIds', validIds);
    }

    // 刷新需求下拉列表数据
    if (issueOfReviewTypes.includes(reviewType)) {
      runSelectIssueList({
        systemIdList: systemIds,
        issueIds:
          props?.isCreated && !props?.isCopy
            ? null
            : issueReviewRef.current?.getFieldValue('issueIds'),
      });
    }

    // 刷新规划下拉列表数据
    if (reviewType === 'SPRINT_REVIEW') {
      runSelectPlanList({
        systemIds: systemIds,
        ids:
          props?.isCreated && !props?.isCopy
            ? null
            : issueReviewRef.current?.getFieldValue('planIds'),
      });
    }

    // 刷新用例下拉列表数据
    if (reviewType === 'TEST_CASE_REVIEW') {
      runSelectTestCaseList({
        systemIds: systemIds,
        ids:
          props?.isCreated && !props?.isCopy
            ? null
            : issueReviewRef.current?.getFieldValue('testCaseIds'),
      });
    }
  };

  // 系统变更时，刷新各个下拉数据
  const changeReviewType = async () => {
    setHidenRelation(false);
    executorCodesRef.current = [];

    // 获取评审类型
    const reviewType = issueReviewRef?.current?.getFieldValue('reviewType');

    // 获取系统id集合
    const systemIds = issueReviewRef?.current?.getFieldValue('systemIdList');

    // 刷新需求下拉列表数据
    if (issueOfReviewTypes.includes(reviewType)) {
      if (systemIds?.length > 0) {
        runSelectIssueList({
          systemIdList: systemIds,
        });
      }
    }

    // 刷新规划下拉列表数据
    if (reviewType === 'SPRINT_REVIEW') {
      if (systemIds?.length > 0) {
        runSelectPlanList({
          systemIds: systemIds,
        });
      }
    }

    // 刷新用例下拉列表数据
    if (reviewType === 'TEST_CASE_REVIEW') {
      if (systemIds?.length > 0) {
        runSelectTestCaseList({
          systemIds: systemIds,
        });
      }
    }

    if (!issueOfReviewTypes.includes(reviewType)) {
      issueReviewRef.current?.setFieldValue('issueIds', []);
    }
    if (reviewType !== 'SPRINT_REVIEW') {
      issueReviewRef.current?.setFieldValue('planIds', []);
    }
    if (reviewType !== 'TEST_CASE_REVIEW') {
      issueReviewRef.current?.setFieldValue('testCaseIds', []);
    }

    if (!havaRelationReviewTypes.includes(reviewType)) {
      setHidenRelation(true);
    }
  };


  useEffect(() => {
    if (props.updateModalOpen) {
      setMeetingRepeatType('-1');
      runUserList();
      setIsSubmitting(false)
      if (props.isCreated) {
        initFields();
        if (initialState?.defaultSystem?.systemDTO?.systemId !== 'all') {
          runSelectIssueList({
            name: '',
            issueIds: props.relationIds,
            systemIdList: [initialState?.defaultSystem?.systemDTO?.systemId],
          });

        } else {
          runSelectIssueList({
            name: '',
            issueIds: props.relationIds,
            systemIdList: props?.systemIdList?.length > 0 ? props?.systemIdList : [null],
          });
        }

        if (props?.relationIds.length > 0) {
          if (issueOfReviewTypes.includes(props?.reviewType)) {
            if (props.detail?.systemIdList?.length > 0) {
              runSelectIssueList({
                systemIdList:
                  initialState?.defaultSystem?.systemDTO?.systemId === 'all'
                    ? []
                    : [initialState?.defaultSystem?.systemDTO?.systemId],
                issueIds: props?.relationIds,
              });
            }
            runExecutorInfo('ISSUE', props?.relationIds);

            issueReviewRef.current?.setFieldValue('reviewName', props?.reviewName);
            issueReviewRef.current?.setFieldValue('issueIds', props?.relationIds);
          }
          if (props?.reviewType === 'SPRINT_REVIEW') {
            if (props.detail?.systemIdList?.length > 0) {
              runSelectPlanList({
                systemIds:
                  initialState?.defaultSystem?.systemDTO?.systemId === 'all'
                    ? []
                    : [initialState?.defaultSystem?.systemDTO?.systemId],
                ids: props?.relationIds,
              });
            }
            runExecutorInfo('PLAN', props?.relationIds);

            issueReviewRef.current?.setFieldValue('planIds', props?.relationIds);
          }
          if (props?.reviewType === 'TEST_CASE_REVIEW') {
            if (props.detail?.systemIdList?.length > 0) {
              runSelectTestCaseList({
                systemIds:
                  initialState?.defaultSystem?.systemDTO?.systemId === 'all'
                    ? []
                    : [initialState?.defaultSystem?.systemDTO?.systemId],
                ids: props?.relationIds,
              });
            }

            issueReviewRef.current?.setFieldValue('testCaseIds', props?.relationIds);
          }
        }
      }

      if (!props.isCreated) {
        //编辑
        setFields();
      } else if (props.isCopy) {
        //克隆
        setChecked(false); //克隆需要检查时间
        issueReviewRef.current?.setFieldsValue({
          reviewName: `${props.detail?.reviewName || ''}-copy`,
          reviewType: props.detail?.reviewType || '',
          inviteesCodes: props.detail?.inviteesCodes || [],
          systemIdList: props.detail?.systemIdList || [],
          uploadAttachments: props.detail?.uploadAttachments || [],
          meetingRepeatType: props.detail?.meetingRepeatType || '-1',
        });
        setMeetingRepeatType(props.detail?.meetingRepeatType || '-1');
        if (issueOfReviewTypes?.includes(props.detail?.reviewType)) {
          if (props.detail?.systemIdList?.length > 0) {
            runSelectIssueList({
              systemIdList: props.detail?.systemIdList,
              issueIds: props.detail?.relationIds,
            });
          }
          issueReviewRef.current?.setFieldValue('issueIds', props.detail?.relationIds);
        }

        if (props.detail?.reviewType === 'SPRINT_REVIEW') {
          if (props.detail?.systemIdList?.length > 0) {
            runSelectPlanList({
              systemIds: props.detail?.systemIdList,
              ids: props.detail?.relationIds,
            });
          }
          issueReviewRef.current?.setFieldValue('planIds', props.detail?.relationIds);
        }

        if (props.detail?.reviewType === 'TEST_CASE_REVIEW') {
          if (props.detail?.systemIdList?.length > 0) {
            runSelectTestCaseList({
              systemIds: props.detail?.systemIdList,
              ids: props.detail?.relationIds,
            });
          }
          issueReviewRef.current?.setFieldValue('testCaseIds', props.detail?.relationIds);
        }
      }
    }
  }, [props.updateModalOpen, props.detail]);

  return (
    <Drawer
      title={
        props.isCreated
          ? props?.isCopy
            ? '克隆评审会议'
            : '创建评审会议'
          : '编辑评审会议'
      }
      width='100%'
      onClose={() => {
        props.onCancel();
      }}
      open={props?.updateModalOpen}
      styles={{
        body: {
          paddingBottom: 80,
        },
      }}
      extra={
        <Space>
          <Button
            onClick={() => {
              setIsSubmitting(false)
              initFields();
              props.onCancel();
            }}
          >
            取消
          </Button>
          <Button
            type="primary"
            loading={isSubmitting}
            onClick={async () => {
              if (isSubmitting) {
                message.warning("正在创建中，请勿重复提交");
                return;
              }

              const param = issueReviewRef.current?.getFieldsValue();
              if (issueOfReviewTypes.includes(param?.reviewType)) {
                param.relationIds = param?.issueIds;
              }
              if (param?.reviewType === 'SPRINT_REVIEW') {
                param.relationIds = param?.planIds;
              }
              if (param?.reviewType === 'TEST_CASE_REVIEW') {
                param.relationIds = param?.testCaseIds;
              }
              if (param?.reviewDate) {
                param.reviewDate = moment(param?.reviewDate).format("YYYY-MM-DD HH:mm:ss");
              }
              if (haveMeeting) {
                if (meetingRepeatType === '-1') {
                  if (meetingRoomIds?.length > 0) {
                    param.meetingRoomId = meetingRoomIds[0];
                    param.deputyRoomIds = meetingRoomIds.slice(1);
                  } else {
                    param.meetingRoomId = null;
                    param.deputyRoomIds = [];
                  }
                }
                const startStamp = moment(param.reviewDate).unix();
                // const endStamp = startStamp + parseInt(param?.meetingDuration);
                if (startStamp * 1000 <= Date.now()) {
                  message.warning("会议开始时间必须在当前时间之后");
                  return;
                }
              }

              message.success('正在创建中，请稍等');
              setIsSubmitting(true);
              let resp: any;
              if (props?.isCreated) {
                resp = await createReview(param);
              } else {
                param.id = props?.detail?.id;
                resp = await updReview(param);
              }
              if (resp.status === 'success') {
                message.success('创建成功！');
                props.onSubmit(param);
              } else {
                message.error(resp?.message);
                setIsSubmitting(false);
              }
            }}
          >
            确定
          </Button>
        </Space>
      }
    >
      <div style={{ width: '420px', float: 'left' }}>
        <ProForm
          formRef={issueReviewRef}
          submitter={{
            searchConfig: {
              resetText: '重置',
              submitText: '提交',
            },
            // 配置按钮的属性
            resetButtonProps: {
              style: {
                // 隐藏重置按钮
                display: 'none',
              },
            },
            submitButtonProps: {},
            // 自定义整个区域
            render: (props, doms) => {
              console.log(props);
              return [];
            },
          }}
          onValuesChange={(changedValues, allValues) => {
            // 在这里可以调用其他方法，做你想要的操作
            // console.log('表单值发生变化', changedValues, allValues);
            // 调用其他方法...
            if (allValues?.meetingRepeatType) {
              setMeetingRepeatType(allValues?.meetingRepeatType);
            }
            if (changedValues.summary) {
              setChecked(true);
            }
          }}
        >
          <ProFormText
            name="reviewName"
            label={
              props?.type === 'sprint'
                ? intl.formatMessage({
                  id: 'pages.issueReview.allName',
                  defaultMessage: '名称',
                })
                : intl.formatMessage({
                  id: 'pages.issueReview.name',
                  defaultMessage: '评审名称',
                })
            }
            width="400px"
            initialValue={props?.reviewName}
            rules={[
              {
                required: true,
              },
            ]}
          />


          <ProFormSelect
            showSearch={true}
            name="reviewType"
            disabled={props?.type === 'sprint' ? false : props?.isDisabled}
            width="400px"
            label={
              props?.type === 'sprint'
                ? intl.formatMessage({
                  id: 'pages.task.type',
                  defaultMessage: '类型',
                })
                : intl.formatMessage({
                  id: 'pages.issueReview.reviewType',
                  defaultMessage: '评审类型',
                })
            }
            rules={[
              {
                required: true,
                message: props?.type === 'sprint' ? '请选择类型' : '请选择评审类型',
              },
            ]}
            onChange={(...row) => changeReviewType(row)}
            initialValue={props?.reviewType}
            options={reviewTypeList?.review_type?.map((item: any) => ({
              label: item.dictLable,
              value: item.dictValue,
            }))}
          />

          <ProFormSelect
            mode={'multiple'}
            showSearch={true}
            name="systemIdList"
            disabled={props?.type === 'sprint' ? true : props?.isDisabled}
            width="400px"
            label={intl.formatMessage({
              id: 'pages.system.title',
              defaultMessage: '系统',
            })}
            rules={[
              {
                required: true,
                message: '请选择系统',
              },
            ]}
            onChange={(...row) => changeSystem(row)}
            initialValue={props?.systemIdList}
            options={systemList
              ?.filter((u) => u.systemId !== 'all')
              ?.map((item) => ({ label: item.systemName, value: item.systemId, ...item }))}
          />


          <ProFormSelect
            disabled={props?.isDisabled}
            hidden={
              !issueOfReviewTypes.includes(issueReviewRef?.current?.getFieldValue('reviewType')) ||
              hidenRelation ||
              props?.type === 'sprint'
            }
            mode={'multiple'}
            showSearch={true}
            name="issueIds"
            width="400px"
            tooltip={'评审需求只能未关闭的需求'}
            label={intl.formatMessage({
              id: 'review.issue',
              defaultMessage: '评审需求',
            })}
            onChange={(...id) => {
              if (!issueReviewRef?.current?.getFieldValue('reviewName')) {
                issueReviewRef?.current?.setFieldValue('reviewName', id[1][0]?.title);
              }

              runExecutorInfo('ISSUE', id[0]);
            }}
            initialValue={props?.relationIds}
            options={selectIssueList?.results?.map((item: any) => ({
              label: item.issueName,
              value: item.id,
            }))}
            fieldProps={{
              onSearch: (name) => {
                runSelectIssueList({
                  name: name,
                  systemIdList:
                    issueReviewRef?.current?.getFieldValue('systemIdList')?.length > 0
                      ? issueReviewRef?.current?.getFieldValue('systemIdList')
                      : ['null'],
                });
              },
            }}
          />

          <ProFormSelect
            disabled={props.isDisabled}
            hidden={
              issueReviewRef?.current?.getFieldValue('reviewType') !== 'SPRINT_REVIEW' ||
              hidenRelation
            }
            mode={'multiple'}
            showSearch={true}
            name="planIds"
            width="400px"
            tooltip={'评审Sprint只能是进行中的Sprint'}
            label={intl.formatMessage({
              id: 'review.sprint',
              defaultMessage: '评审Sprint',
            })}
            onChange={(...id) => {
              issueReviewRef.current?.setFieldValue('reviewName', id[1][0]?.title);
              runExecutorInfo('PLAN', id[0]);
            }}
            initialValue={props?.relationIds}
            options={selectPlanList?.map((item: any) => ({
              label: item.planName,
              value: item.id,
            }))}
            fieldProps={{
              onSearch: (name) => {
                runSelectPlanList({
                  name: name,
                  systemIds:
                    issueReviewRef.current?.getFieldValue('systemIdList')?.length > 0
                      ? issueReviewRef.current?.getFieldValue('systemIdList')
                      : ['null'],
                });
              },
            }}
          />

          <ProFormSelect
            disabled={props.isDisabled}
            hidden={
              issueReviewRef.current?.getFieldValue('reviewType') !== 'TEST_CASE_REVIEW' ||
              hidenRelation
            }
            mode={'multiple'}
            showSearch={true}
            name="testCaseIds"
            width="400px"
            tooltip={'评审测试用例只能是未完成评审的用例'}
            label={intl.formatMessage({
              id: 'review.test.case',
              defaultMessage: '评审测试用例',
            })}
            onChange={(...id) => {
              issueReviewRef.current?.setFieldValue('reviewName', id[1][0]?.title);
            }}
            initialValue={props.relationIds}
            options={selectTestCaseList?.map((item: any) => ({
              label: item.caseName,
              value: item.id,
            }))}
            fieldProps={{
              onSearch: (name) => {
                runSelectTestCaseList({
                  name: name,
                  systemIds:
                    issueReviewRef.current?.getFieldValue('systemIdList')?.length > 0
                      ? issueReviewRef.current?.getFieldValue('systemIdList')
                      : ['null'],
                });
              },
            }}
          />

          <ProFormGroup>
            <Row gutter={24} style={{ width: '400px' }}>
              <Col span={12}>
                <ProFormDateTimePicker
                  name="reviewDate"
                  // width="180px"
                  label={
                    props?.type === 'sprint'
                      ? intl.formatMessage({
                        id: 'pages.issuePool.allReviewDate',
                        defaultMessage: '时间',
                      })
                      : intl.formatMessage({
                        id: 'pages.issuePool.reviewDate',
                        defaultMessage: '评审时间',
                      })
                  }
                  rules={[
                    {
                      required: checked ? false : true,
                      validator: (_, value) => {
                        if (moment(value).isBefore(moment()) && !checked) {
                          return Promise.reject('时间不能小于当前时间');
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                  fieldProps={{
                    disabledDate: (current) => {
                      return current && current < moment().startOf('days');
                    },
                    onChange: (val) => {
                      if (val) {
                        const startStamp = moment(
                          issueReviewRef?.current?.getFieldValue('reviewDate'),
                        ).unix();
                        const endStamp =
                          parseInt(startStamp) +
                          parseInt(issueReviewRef?.current?.getFieldValue('meetingDuration'));
                        setReviewStartTime(startStamp);
                        setReviewEndTime(endStamp);
                      } else {
                        setReviewStartTime(null);
                        setReviewEndTime(null);
                      }
                      setMeetingRoomIds([]);
                      setMeetingRoomName(null)
                      setReloadTableId(Math.random().toString())
                    }
                  }}
                />
              </Col>
              <Col span={12}>
                <ProFormSelect
                  showSearch={true}
                  name="meetingRepeatType"
                  // width={120}
                  initialValue={'-1'}
                  label={props?.type === 'sprint' ? '周期' : '评审（会议）周期'}
                  options={[
                    { label: '不重复', value: '-1' },
                    { label: '每天重复', value: '0' },
                    { label: '每个工作日重复', value: '7' },
                    { label: '每周重复', value: '1' },
                    { label: '每月重复', value: '2' },
                  ]}
                  rules={[{ required: true, message: '请选择周期' }]}
                />
              </Col>

            </Row>
          </ProFormGroup>

          <ProFormSelect
            showSearch={true}
            mode={'multiple'}
            name="inviteesCodes"
            width="400px"
            tooltip={'评审（会议）创建人将自动成为参与人，无需额外选择'}
            label={
              <>
                {props?.type === 'sprint'
                  ? intl.formatMessage({
                    id: 'pages.task.taskParticipants',
                    defaultMessage: '参与人',
                  })
                  : intl.formatMessage({
                    id: 'pages.issueReview.reviewUsers',
                    defaultMessage: '评审参与人',
                  })}
              </>
            }
            initialValue={props?.type === 'sprint' ? props?.relationUsers : null}
            options={userList?.map((item: any) => ({
              label: item.userName,
              value: item.userCode,
            }))}
            value={executorCodesRef.current}
            rules={[{ required: true, message: '请选择评审（会议）参与人' }]}
          />

          <ProFormGroup>
            <Row gutter={24} style={{ width: '400px' }}>
              <Col span={12}>
                <ProFormSwitch
                  checkedChildren="预约"
                  unCheckedChildren="不预约"
                  name="haveMeeting"
                  tooltip={'如果选择预约，在提交表单时会直接预约一个会议'}
                  label={intl.formatMessage({
                    id: 'whether.to.schedule.a.meeting',
                    defaultMessage: '是否预约会议',
                  })}
                  initialValue={haveMeeting}
                  onChange={(val) => {
                    setHaveMeeting(val);
                  }}
                />
              </Col>
              <Col span={12}>
                <ProFormRadio.Group
                  name="meetingDuration"
                  // width="200px"
                  hidden={!haveMeeting}
                  label={intl.formatMessage({
                    id: 'pages.issueReview.form.meetingTime',
                    defaultMessage: '会议时长',
                  })}
                  initialValue={'1800'}
                  width="md"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                  options={[
                    {
                      label: '30分钟',
                      value: '1800',
                    },
                    {
                      label: '1小时',
                      value: '3600',
                    },
                  ]}
                  fieldProps={{
                    onChange: (val) => {
                      if (val) {
                        const startStamp = moment(
                          issueReviewRef?.current?.getFieldValue('reviewDate'),
                        ).unix();
                        const endStamp =
                          parseInt(startStamp) +
                          parseInt(val?.target?.value);
                        setReviewStartTime(startStamp);
                        setReviewEndTime(endStamp);
                      } else {
                        setReviewStartTime(null);
                        setReviewEndTime(null);
                      }
                      setMeetingRoomIds([]);
                      setMeetingRoomName(null)
                      setReloadTableId(Math.random().toString())
                    }
                  }}
                />
              </Col>
            </Row>
          </ProFormGroup>

          <ProFormSelect
            showSearch={true}
            mode={'multiple'}
            name="remindBefores"
            width="400px"
            hidden={!haveMeeting}
            label={intl.formatMessage({
              id: 'pages.issueReview.meetingRemindBefores',
              defaultMessage: '开始前提醒',
            })}
            options={[
              {
                label: '会议开始时提醒',
                value: 0,
              },
              {
                label: '5分钟前提醒',
                value: 300,
              },
              {
                label: '15分钟前提醒',
                value: 900,
              },
              {
                label: '一小时前提醒',
                value: 3600,
              },
              {
                label: '一天前提醒',
                value: 86400,
              },
            ]}
            initialValue={[900]}
            rules={[{ required: true }]}
          />

          <ProFormTextArea
            width={400}
            placeholder="请输入内容"
            name="description"
            fieldProps={{ autoSize: { minRows: 6, maxRows: 20 } }}
            label={
              props?.type === 'sprint'
                ? intl.formatMessage({
                  id: 'description',
                  defaultMessage: '描述',
                })
                : intl.formatMessage({
                  id: 'pages.issueReview.reviewDesc',
                  defaultMessage: '评审描述',
                })
            }
          />

        </ProForm>
      </div>


      <div style={{ width: 'calc(100% - 420px)', float: 'left' }}>
        <Card
          title={
            <Space>
              <span style={{ color: 'green', fontSize: 12 }}>当前评审时间段空闲的会议室列表</span>
            </Space>
          }
          bordered={false}
          style={{ width: '100%', float: 'left' }}
        >
          <div hidden={!issueReviewRef.current?.getFieldValue("reviewDate") || meetingRepeatType !== '-1' || !haveMeeting}>
            <MeetingRoomTable
              reloadId={reloadTableId}
              reviewStartTime={reviewStartTime}
              reviewEndTime={reviewEndTime}
              meetingRoomIds={meetingRoomIds}
              meetingRoom={meetingRoomName}
              onCancel={() => {
                setHandleRoomsOpen(false);
              }}
              onSubmit={async (value) => {
                setMeetingRoomIds(value?.roomIds)
                setMeetingRoomName(value.roomName);
                setHandleRoomsOpen(false);
              }}
              isModalOpen={handleRoomsOpen}
            />
          </div>


        </Card>
      </div>
    </Drawer>
  );
};

export default EditReviewDrawer;
