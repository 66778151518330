import React, { useEffect, useState } from 'react';
import { DatePicker, Spin } from 'antd';
import moment from 'moment';
import { useRequest } from '@@/exports';
import { getHolidays } from '@/services/rd/issues/api';
import dayjs from 'dayjs';

export interface HolidayCalendarProps {
  /** 日期字段的值 */
  defaultValue?: any;
  /** 表单日期字段的 onChange */
  onChange?: (dates: any, workDays?: number) => void;
  /** 禁用日期 */
  disabledDate?: (current: any) => boolean;
  /** 样式 */
  style?: any;
}
const HolidayCalendar: React.FC<HolidayCalendarProps> = ({
  defaultValue,
  onChange,
  disabledDate,
  style,
}) => {
  const [data, setData] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedDates, setSelectedDates] = useState<any>([]); // 保存选中的日期
  const [panelYear, setPanelYear] = useState<string>(''); // 保存当前面板年份
  const [open, setOpen] = useState<boolean>(false); // 日历是否打开

  /**获取节假日内容 */
  const { run: holidaysRun } = useRequest((year: string) => getHolidays(year), {
    manual: true,
    onSuccess: (result: any) => {
      setData((pre: any) => [...(pre || []), ...result]);
      setLoading(false);
    },
    onError: () => setLoading(false),
  });

  // 日历编辑模式初始化（日历不展开也需要设置）
  useEffect(() => {
    if (defaultValue) {
      setSelectedDates(defaultValue);
    }
  }, [defaultValue]);

  //日历初始化
  useEffect(() => {
    if (open) {
      if (defaultValue?.length > 0 && defaultValue?.[0] !== undefined) {
        setPanelYear(String(defaultValue?.[0]?.year()));
      } else {
        setPanelYear(moment().format('YYYY'));
      }
    } else {
      setPanelYear(''); // 关闭日历时，重置面板年份为空
    }
  }, [open]);

  //监听面板(展开状态)年份变化，获取节假日数据
  useEffect(() => {
    if (panelYear && open) {
      setLoading(true);
      holidaysRun(panelYear);
    }
  }, [panelYear, open]);

  // 计算工作日天数
  const calculateWorkDays = (startDate: any, endDate: any) => {
    let workDays = 0;
    let current = dayjs(startDate);
    const end = dayjs(endDate);
    while (current.isBefore(end.add(1, 'day'), 'day')) {
      const dateStr = current.format('YYYY-MM-DD');
      const holiday = data?.find((day: any) => day.date === dateStr);

      if (holiday) {
        // status: 1-工作日, 2-节假日
        // badDay: 1-需要补班
        if (holiday.status === 1 || holiday.badDay === 1) {
          workDays++;
        }
      } else {
        // 如果不是节假日，且是工作日（周一到周五）
        if (current.day() !== 0 && current.day() !== 6) {
          workDays++;
        }
      }
      current = current.add(1, 'day');
    }

    return workDays;
  };

  const cellRender = (date: moment.Moment) => {
    const holiday = data?.find((day: any) => day.date === date.format('YYYY-MM-DD'));

    // 节假日
    if (holiday) {
      return (
        <div className="ant-picker-cell-inner">
          {date.date()}
          {(holiday.festival || holiday.status === 2 || holiday.badDay === 1) && (
            <div
              style={{
                position: 'absolute',
                ...(holiday.festival
                  ? {
                      color: 'red',
                      fontSize: 12,
                      whiteSpace: 'nowrap', //禁止换行
                      top: 6,
                      left: holiday.festival?.length > 2 ? -4 : 0,
                      transform: 'translate(0%,0%) scale(0.6)', //位置不变大小乘0.6
                      transformOrigin: 'center bottom', //元素会从底部中心开始缩放
                    }
                  : {
                      left: 4,
                      color: '#fff',
                      bottom: 2,
                      borderRadius: '50%',
                      fontSize: 12,
                      transform: 'translate(50%,50%) scale(0.7)',
                      lineHeight: 1.7,
                      background: holiday.status === 2 ? 'red' : 'green',
                      width: 20,
                      height: 20,
                    }),
              }}
            >
              {holiday.festival || (holiday.status === 2 ? '假' : '班')}
            </div>
          )}
        </div>
      );
    }
    // 非节假日使用默认渲染
    return <div className="ant-picker-cell-inner">{date.date()}</div>;
  };

  return (
    <Spin spinning={loading} size="small">
      <DatePicker.RangePicker
        open={open}
        onOpenChange={setOpen}
        onPanelChange={(dates) => {
          setPanelYear(String(dates?.[0]?.year())); // 面板年份切换时重新获取数据
        }}
        value={selectedDates}
        cellRender={cellRender as any}
        style={style}
        disabledDate={disabledDate}
        onChange={(dates) => {
          setSelectedDates(dates);
          if (dates && dates?.[0] && dates?.[1]) {
            const workDays = calculateWorkDays(dates[0], dates[1]);
            onChange?.(dates, workDays);
          } else {
            onChange?.(dates);
          }
        }}
        mode={['date', 'date']}
      />
    </Spin>
  );
};

export default HolidayCalendar;
