import Footer from '@/components/Footer';
import { SelectLang, NoticeIcon, SelectSystem, AddTask } from '@/components/RightContent';
import { LinkOutlined, QuestionCircleOutlined, SmileOutlined } from '@ant-design/icons';
import { Settings as LayoutSettings } from '@ant-design/pro-components';
import { SettingDrawer } from '@ant-design/pro-components';
import type { RunTimeLayoutConfig } from '@umijs/max';
import { history, Link } from '@umijs/max';
import defaultSettings from '../config/defaultSettings';
import { errorConfig } from './requestErrorConfig';
import {
  currentUser as queryCurrentUser,
  getTokenInfo,
  getUserSystemCache,
  listSchedulingUser,
  listChangeSystem,
} from '@/services/common/api';
import React, { Component, useEffect } from 'react';
import { AvatarDropdown, AvatarName } from './components/RightContent/AvatarDropdown';
import logo from './logo.png';
import { ConfigProvider, Row, Col, Tag, Typography, Button, Result, Tooltip, Alert } from 'antd';

import IssueSearch from './components/IssueSearch';
import FindPeople from './components/FindPeople';
import NotificationWebSocket from '@/components/NotificationWebSocket';
import Marquee from 'react-fast-marquee';

const isDev = process.env.NODE_ENV === 'development';
const loginPath = '/user/login';

// 不需要登录的页面路由
const noLoginPath = ['/questionnaire'];

// 获取页面路由
const hashPath = window.location.hash; // 获取 hash 部分
const pathname = hashPath.replace('#', '').split('?')[0]; // 提取 页面路由

/**
 * @see  https://umijs.org/zh-CN/plugins/plugin-initial-state
 * */
export async function getInitialState(): Promise<{
  settings?: Partial<LayoutSettings>;
  currentUser?: API.CurrentUser;
  roles?: any[];
  systemList: any[];
  permissions?: [];
  schedulingUser?: object;
  loading?: boolean;
  fetchUserInfo?: () => Promise<any | undefined>;
  defaultSystem: any;
}> {
  const fetchUserInfo = async () => {
    const { location } = history;
    if (!localStorage.getItem('token')) {
      // console.log(location)
      const callbackUrl = location.search;
      const getqyinfo = callbackUrl.split('?')[1];
      // const indexBack = getqyinfo.split('#')[0];
      const obj = await getTokenInfo(getqyinfo);
      localStorage.setItem('token', obj.token);
      history.push('/');
    }

    try {
      const userInfo = await queryCurrentUser({
        skipErrorHandler: true,
      });

      if (userInfo?.code === 500) {
        alert(userInfo?.msg);
        window.location.href =
          'https://sso.imile-inc.com/login/oauth/authorize?client_id=e8f7e762ad8e6f186d3a&response_type=code&redirect_uri=' +
          `${API_SERVER}` +
          '%2F%23%2Fcallback&scope=read&state=casdoor';
      }
      //探针
      if ((REACT_APP_ENV === 'test' && !isDev) || REACT_APP_ENV === 'prod') {
        const env = REACT_APP_ENV === 'prod' ? 'pro' : 'dev';
        window.localStorage.wmUserInfo = JSON.stringify({ projectVersion: '1.0.1', env: env });
        window.localStorage.wmUserInfo = JSON.stringify({
          userId: userInfo?.user?.userName,
          userTag: 'tag',
          projectVersion: '1.0.1',
          env: env,
        });
      }

      return userInfo;
    } catch (error) {
      history.push(loginPath);
    }
    return undefined;
  };

  const getDefaultSystem = async () => {
    try {
      const systemId = await getUserSystemCache({
        skipErrorHandler: true,
      });
      return systemId;
    } catch (error) {
      console.log(error);
    }
    return undefined;
  };

  const listSystems = async () => {
    try {
      const systems = await listChangeSystem({
        skipErrorHandler: true,
      });
      return systems;
    } catch (error) {
      console.log(error);
    }
    return undefined;
  };

  // 如果不是登录页面，执行
  if (location.pathname !== loginPath && !(noLoginPath.indexOf(pathname) > -1)) {
    const date = new Date();
    const userInfo = await fetchUserInfo();
    const systemId = await getDefaultSystem();
    const scheduling = await listSchedulingUser({
      date: date.getDay(),
    });
    const systems = await listSystems();
    // console.log(systemId)
    const currentUser = userInfo?.user;
    const roles = userInfo?.roles;
    const permissions = userInfo?.permissions;
    const schedulingUser = scheduling?.resultObject?.[0]?.onDutyDTO;
    const defaultSystem = systemId?.resultObject || '';
    const systemList = systems?.resultObject || [];
    const settings = defaultSettings as Partial<LayoutSettings>;
    if (localStorage.getItem('navTheme')) {
      settings.navTheme = localStorage.getItem('navTheme');
    }
    return {
      fetchUserInfo,
      currentUser,
      roles,
      permissions,
      schedulingUser,
      defaultSystem,
      systemList,
      settings: settings || (defaultSettings as Partial<LayoutSettings>),
    };
  }
  return {
    currentUser: undefined,
    defaultSystem: '',
    loading: false,
    permissions: [],
    roles: [],
    schedulingUser: undefined,
    fetchUserInfo,
    systemList: [],
    settings: defaultSettings as Partial<LayoutSettings>,
  };
}

// ProLayout 支持的api https://procomponents.ant.design/components/layouthttps://procomponents.ant.design/components/layout
export const layout: RunTimeLayoutConfig = ({ initialState, setInitialState }) => {
  // 免登页面只需加载内容，无需加载菜单等
  if (!(noLoginPath.indexOf(pathname) > -1)) {
    return {
      actionsRender: () => {
        return [
          <Alert
            key="alert"
            banner
            style={{
              width: '100%',
              cursor: 'default',
            }}
            message={
              <Marquee pauseOnHover gradient={false} speed={30} delay={2}>
                <span
                  style={{
                    fontSize: 13,
                    letterSpacing: '0.2em',
                  }}
                >
                  每一起严重事故的背后，必然有
                  <span style={{ fontWeight: 'bold' }}>29次轻微事故</span>和
                  <span style={{ fontWeight: 'bold' }}>300起未遂先兆</span>以及
                  <span style={{ fontWeight: 'bold' }}>1000起事故隐患</span>。
                  <a
                    style={{ marginRight: '15px' }}
                    onClick={() => {
                      history.push('/rd/deliveryQuality/incidentResponse');
                    }}
                  >
                    📞 联系值班人员
                  </a>
                </span>
              </Marquee>
            }
          />,
          <IssueSearch key="IssueSearch" />,
          <AddTask key="addTask" />,
          <SelectSystem key="SelectSystem" />,
          // <SelectLang key="SelectLang" />,
          <NoticeIcon key="NoticeIcon" />,
          <Tooltip title="使用文档">
            <QuestionCircleOutlined
              style={{ color: '#00BC73', marginLeft: 5 }}
              key="help"
              onClick={() => {
                window.open(`${API_SERVER}/help`);
              }}
            />
          </Tooltip>,
        ];
      },

      avatarProps: {
        src: initialState?.currentUser?.wxPicture,
        title: (
          <span
            style={{
              maxWidth: 'none',
              whiteSpace: 'nowrap',
              overflow: 'visible',
              textOverflow: 'clip',
            }}
          >
            <AvatarName />
          </span>
        ),
        render: (_, avatarChildren) => {
          return <AvatarDropdown menu={false}>{avatarChildren}</AvatarDropdown>;
        },
      },
      siderWidth: 218,
      defaultCollapsed: true,
      breakpoint: false,
      // title: <Space><h1>研发效能平台</h1><Input.Search style={{ marginLeft: 70 }} bordered={false} placeholder="input search text" onSearch={() => { console.log(11111) }} enterButton /></Space>,
      onCollapse: (collapsed) => {
        setInitialState((preInitialState) => ({
          ...preInitialState,
          settings: {
            ...(preInitialState.settings || {}),
            collapsed,
          },
        }));
      },
      headerTitleRender: (logo, title, _) => {
        return (
          <a>
            {logo}
            <span
              style={{
                whiteSpace: 'nowrap',
                overflow: 'visible',
                fontSize: '16px', // 可以调整字体大小以适应空间
              }}
            >
              {title}
            </span>
          </a>
        );
      },
      waterMarkProps: {
        fontSize: 13,
        content: [
          initialState?.currentUser?.nickName,
          new Date().toString().replace(/\s*$.*?$$/, ''),
        ],
        markStyle: { opacity: 0.4 },
      },
      logo: logo,
      // footerRender: () => <Footer/>,
      onPageChange: () => {
        const { location } = history;
        // 如果没有登录，重定向到 login
        if (!initialState?.currentUser && location.pathname !== loginPath) {
          history.push(loginPath);
        }
      },
      bgLayoutImgList: [
        {
          src: 'https://mdn.alipayobjects.com/yuyan_qk0oxh/afts/img/D2LWSqNny4sAAAAAAAAAAAAAFl94AQBr',
          left: 85,
          bottom: 100,
          height: '303px',
        },
        {
          src: 'https://mdn.alipayobjects.com/yuyan_qk0oxh/afts/img/C2TWRpJpiC0AAAAAAAAAAAAAFl94AQBr',
          bottom: -68,
          right: -45,
          height: '303px',
        },
        {
          src: 'https://mdn.alipayobjects.com/yuyan_qk0oxh/afts/img/F6vSTbj8KpYAAAAAAAAAAAAAFl94AQBr',
          bottom: 0,
          left: 0,
          width: '331px',
        },
      ],
      links: isDev
        ? [
            <Link key="openapi" to="/umi/plugin/openapi" target="_blank">
              <LinkOutlined />
              <span>OpenAPI 文档</span>
            </Link>,
          ]
        : [],
      menuHeaderRender: undefined,
      // menuDataRender: (menuData) => {
      //   return menuData.map(item => {
      //     if (item.name === 'NextChat') {
      //       item.icon = <><SmileOutlined style={{ marginRight: 9 }} />{item.name}<Tag color='red' style={{ width: 25, height: 15, fontSize: 7, lineHeight: '14px', paddingInline: 3, marginInlineStart: 3 }}>NEW</Tag></>
      //     } return item
      //   })
      // },
      menuDataRender: (menuData) => {
        const isCollapsed = initialState?.settings?.collapsed;
        const nameMapping: Record<string, string> = {
          Dashboard: '概览',
          工作台: '工作台',
          项目管理: '项目',
          研发协同: '研发',
          发布看板: '发布',
          效能洞察: '效能',
          Tools: '工具',
          CMDB: 'CMDB',
          设置: '设置',
        };
        return menuData.map((item) => {
          if (isCollapsed === undefined || isCollapsed === true) {
            return {
              ...item,
              icon: (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    marginBottom: '20px',
                    transform:
                      item.name === 'CMDB' ? 'translate(-20%, 40%)' : 'translate(-15%, 32%)', //左、下调整
                  }}
                >
                  {item.icon}
                  <div style={{ fontSize: '10px', color: '#888' }}>
                    {nameMapping[item.name || ''] || item.name}
                  </div>
                </div>
              ),
            };
          }
          return item;
        });
      },
      // 自定义 403 页面
      // unAccessible: <div>unAccessible</div>,
      // 增加一个 loading 的状态
      childrenRender: (children) => {
        // if (initialState?.loading) return <PageLoading />;
        return (
          <ConfigProvider
            componentSize={'small'}
            theme={{
              token: {
                fontSize: 12,
              },
            }}
          >
            <ErrorBoundary>{children}</ErrorBoundary>
            <FindPeople />
            <NotificationWebSocket />
            {isDev && (
              <SettingDrawer
                disableUrlParams
                enableDarkTheme
                settings={initialState?.settings}
                onSettingChange={(settings) => {
                  setInitialState((preInitialState) => ({
                    ...preInitialState,
                    settings,
                  }));
                }}
              />
            )}
          </ConfigProvider>
        );
      },
      ...initialState?.settings,
      // 菜单支持拖拽
      // menuExtraRender: () => {
      //   return (
      //     <div
      //       style={{
      //         position: 'absolute',
      //         right: -5,
      //         top: 0,
      //         bottom: 0,
      //         width: 10,
      //         cursor: 'ew-resize',
      //       }}
      //       onMouseDown={(e) => {
      //         e.preventDefault();
      //         const startX = e.clientX;

      //         function handleMouseMove(event: MouseEvent) {
      //           const diff = event.clientX - startX;
      //           // 根据拖拽方向设置折叠状态
      //           setInitialState((prev: any) => ({
      //             ...prev,
      //             settings: {
      //               ...prev.settings,
      //               collapsed: diff < 0, // 往左拖就折叠，往右保持展开
      //             },
      //           }));
      //         }

      //         function handleMouseUp() {
      //           document.removeEventListener('mousemove', handleMouseMove);
      //           document.removeEventListener('mouseup', handleMouseUp);
      //         }

      //         document.addEventListener('mousemove', handleMouseMove);
      //         document.addEventListener('mouseup', handleMouseUp);
      //       }}
      //     />
      //   );
      // },
    };
  } else {
    return {
      pure: true,
      colorPrimary: '#00c479',
    };
  }
};

/**
 * @name request 配置，可以配置错误处理
 * 它基于 axios 和 ahooks 的 useRequest 提供了一套统一的网络请求和错误处理方案。
 * @doc https://umijs.org/docs/max/request#配置
 */
export const request = {
  formatResult: (result) => {
    return result?.resultObject;
  },
  ...errorConfig,
};

class ErrorBoundary extends Component {
  constructor(props: {} | Readonly<{}>) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  // 当子组件抛出错误时，这个生命周期方法会被调用
  static getDerivedStateFromError(error) {
    // 更新state，以便下次渲染时可以显示备用UI
    return { hasError: true };
  }

  // 此生命周期方法在子组件抛出错误后被调用
  componentDidCatch(error, errorInfo) {
    // 你可以在这里处理错误，例如记录错误信息
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
    // 例如，发送错误报告到你的服务端
    // logErrorToMyService(error, errorInfo);
  }

  render() {
    if (this.state?.hasError) {
      console.log(this.state);
      // 自定义备用UI
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '70vh', // 设置父容器高度为视口高度
          }}
        >
          <Result
            title="The page has been updated, please refresh."
            subTitle="If the refresh doesn't work, please click the '?' in the bottom right corner to provide feedback."
            extra={
              <Button type="primary" onClick={() => window.location.reload()}>
                Refresh
              </Button>
            }
          />
        </div>
      );
    }

    // 如果没有错误，正常渲染子组件
    return this.props.children;
  }
}
