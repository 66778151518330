import { Avatar, Modal, Space, Table, Tooltip, message, Tag } from 'antd';
import React, { useEffect, useState, useRef } from "react";
import { FormattedMessage, useModel, useRequest } from "@@/exports";
import { getMeetingRooms, getMeetingRoomLinkageInfo } from "@/services/rd/issues/api";
import { ActionType, ProColumns, ProTable } from '@ant-design/pro-components';
import { CheckOutlined, CloseCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { useEmotionCss } from "@ant-design/use-emotion-css";
import { all } from 'ace-builds-internal/config';


export type IssueTableProps = {
  // onCancel: (flag?: boolean) => void;
  onSubmit: any;
  reviewStartTime: any;
  reviewEndTime: any;
  reloadId: any;
  meetingRoomIds: any;
  meetingRoom: any
};




const MeetingRoomTable: React.FC<IssueTableProps> = (props) => {
  const actionRef = useRef<ActionType>();
  const formRef = useRef<FormInstance>();

  // const [rooms, setRooms] = useState<[]>([]);
  const [meetingRoomLinkageInfo, setMeetingRoomLinkageInfo] = useState<{}>({});

  const [newCity, setNewCity] = useState(undefined);
  const [oldCity, setOldCity] = useState(undefined);

  const [newBuilding, setNewBuilding] = useState(undefined);
  const [oldBuilding, setOldBuilding] = useState(undefined);

  const [newFloor, setNewFloor] = useState(undefined);
  const [oldFloor, setOldFloor] = useState(undefined);

  const [buildings, setBuildings] = useState<[]>([]);
  const [floors, setFloors] = useState<[]>([]);

  const [selectedRows, setSelectedRows] = useState([] as any);
  const [selectedKeys, setSelectedKeys] = useState([] as any);
  const [roomInfo, setRoomInfo] = useState({});
  const [selectedDescription, setSelectedDescription] = useState('');



  // 获取会议室位置联动信息
  const { run: runMeetingRoomLinkageInfo } = useRequest(() => {
    return getMeetingRoomLinkageInfo()
  }, {
    manual: true,
    onSuccess: (res) => {
      setMeetingRoomLinkageInfo(res);
    }
  });

  useEffect(() => {
    setSelectedKeys(props?.meetingRoomIds ? props?.meetingRoomIds : []);
    setSelectedDescription(props?.meetingRoomIds?.length > 0 ? `已选择${props?.meetingRoomIds?.length}个会议室：${props?.meetingRoom}` : '');
    setSelectedRows(props?.meetingRoomIds ? props?.meetingRoomIds?.map(u => { meetingRoomId: u }) : [])

    runMeetingRoomLinkageInfo();
    actionRef?.current?.reload();

  }, [props.reloadId, props?.meetingRoomIds]);

  const tableClass = useEmotionCss(({ token }) => {
    return {
      '.ant-pro-table-list-toolbar-container': {
        paddingBlock: 0,
        paddingInline: 5
      },
      '.ant-pro-table-extra': {
        marginBlockEnd: 0
      },

    }
  })


  const columns: ProColumns[] = [
    {
      title: <FormattedMessage id="meeting.room.name" defaultMessage="会议室名称" />,
      dataIndex: 'name',
      ellipsis: true,
      align: 'left',
      search: false,
      width: 200,
    },

    {
      title: '状态',
      dataIndex: 'status',
      ellipsis: true,
      align: 'center',
      search: false,
      width: 80,
      render: (_, row) => {
        return <Tag color='green'>空闲</Tag>
      },
    },

    {
      title: <FormattedMessage id="city" defaultMessage="城市" />,
      dataIndex: 'city',
      ellipsis: true,
      align: 'center',
      search: false,
      width: 120,
      filterMultiple: false,
      filters: meetingRoomLinkageInfo?.citys?.map((item: any) => ({
        text: item,
        value: item,
      })),
      render: (_, row) => {
        return <span>{row?.city}</span>
      },
    },

    {
      title: <FormattedMessage id="building" defaultMessage="建筑" />,
      dataIndex: 'building',
      ellipsis: true,
      align: 'center',
      search: false,
      width: 120,
      filterMultiple: false,
      filters: buildings?.map((item: any) => ({
        text: item,
        value: item,
      })),
      render: (_, row) => {
        return <span>{row?.building}</span>
      },
    },

    {
      title: <FormattedMessage id="floor" defaultMessage="楼层" />,
      dataIndex: 'floor',
      ellipsis: true,
      align: 'center',
      search: false,
      width: 120,
      filterMultiple: false,
      filters: floors?.map((item: any) => ({
        text: item,
        value: item,
      })),
      render: (_, row) => {
        return <span>{row?.floor}</span>
      },
    },

    {
      title: <FormattedMessage id="meeting.room.capacity" defaultMessage="会议室容量" />,
      dataIndex: 'capacitySign',
      ellipsis: true,
      align: 'center',
      search: false,
      width: 120,
      filterMultiple: false,
      filters: [{ value: 0, text: "12人以下" }, { value: 1, text: "13~20人" }, { value: 2, text: "20人以上" }],
      render: (_, row) => {
        return <Tag>{row?.capacity}</Tag>
      },
    },

    {
      title: <FormattedMessage id="meeting.room.equipment" defaultMessage="会议室设备" />,
      dataIndex: 'equipmentIds',
      ellipsis: true,
      align: 'left',
      search: false,
      width: 120,
      filterMultiple: true,
      filters: [{ value: 1, text: "电视" }, { value: 2, text: "电话" }, { value: 3, text: "投影" }
        , { value: 4, text: "白板" }, { value: 5, text: "视频" }
      ],
      render: (_, row) => {
        return <span>{row?.equipmentNames}</span>
      },
    },

  ];



  return (
    <>
      <ProTable
        actionRef={actionRef}
        tableStyle={{ fontSize: 10 }}
        rowKey='meetingRoomId'
        className={tableClass}
        formRef={formRef}
        search={false}
        headerTitle={<div><span style={{ fontSize: '12px', fontWeight: 'bold' }}>{selectedDescription}</span></div>}
        request={async (
          params: T & {
          },
          sort: any,
          filter: any,) => {

          setBuildings([]);
          setFloors([]);

          const param = {
            startTime: props?.reviewStartTime,
            endTime: props?.reviewEndTime,
            equipment: filter?.equipmentIds,
            capacitySign: filter?.capacitySign?.[0],
            city: filter?.city?.[0],
            building: (filter?.city?.[0] === oldCity) ? filter?.building?.[0] : undefined,
            floor: (filter?.city?.[0] === oldCity && filter?.building?.[0] === oldBuilding) ? filter?.floor?.[0] : undefined,
          }

          setOldCity(param?.city);
          setOldBuilding(param?.building);
          setOldFloor(param?.floor);

          if (param?.city !== undefined) {
            setBuildings(meetingRoomLinkageInfo?.buildingsMap[param?.city]);
          }

          if (param?.building !== undefined) {
            setFloors(meetingRoomLinkageInfo?.floorsMap[param?.building]);
          }

          const resp = await getMeetingRooms(param);
          return {
            data: resp?.resultObject,
            success: true,
            total: resp?.resultObject.length,
          }
        }}
        columns={columns}

        rowSelection={{
          onChange: (_, selectedRows) => {
            setSelectedRows(selectedRows);
            if (selectedRows.length > 0) {
              const roomNames = selectedRows?.map(u => u.name + "(" + u.building + "-" + u.floor + ")").join(", ");;
              setSelectedDescription(`已选择${roomNames.length}个会议室：${roomNames}`);
              setSelectedKeys(selectedRows?.map(u => u?.meetingRoomId))

              if (selectedRows?.length > 6) {
                message.warning("最多只能选6个会议室");
                setSelectedKeys([]);
                setSelectedDescription('');
                setSelectedRows([])
              } else {
                props.onSubmit({ roomName: roomNames, roomIds: selectedRows?.map(u => u?.meetingRoomId) });
              }
            } else {
              setSelectedKeys([]);
              setSelectedDescription('');
            }
          },
          selectedRowKeys: selectedKeys || [],
          getCheckboxProps: (record) => ({
            // 当有行被选中时，禁用其他行的复选框
            disabled: selectedRows.length > 5,
          }),
        }}
        defaultSize={"small"}
        scroll={{ y: 'calc(100vh - 350px)' }}
      />

    </>
  );
};

export default MeetingRoomTable;
