import { Button, message } from 'antd';
import copy from 'copy-to-clipboard';


/**根据下标获取颜色 */
export function getColorByIndex(i) {
  if (i % 3 === 0) {
    return 'blue';
  }
  if (i % 3 === 1) {
    return 'green';
  }
  if (i % 3 === 2) {
    return 'orange';
  }
}

export function getCommitContent(sn, name) {
  return (
    <div
      style={{
        width: '150px',
        textAlign: 'center',
        display: 'flex',
        gap: '2px',
        flexDirection: 'column',
      }}
    >
      <div>
        <Button
          style={{ border: 'none', width: '100%', backgroundColor: 'rgba(248, 166, 166, 0.39)' }}
          onClick={() => {
            const textToCopy = sn;
            copy(textToCopy);
            message.success('序列号已复制至剪贴板');
          }}
        >
          点击复制序列号
        </Button>
      </div>
      <div>
        <Button
          style={{ border: 'none', width: '100%', backgroundColor: 'rgba(246, 213, 156, 0.5)' }}
          onClick={() => {
            const textToCopy = '[' + sn + '] ' + name;
            copy(textToCopy);
            message.success('规范commit内容已复制至剪贴板');
          }}
        >
          点击复制规范commit
        </Button>
      </div>
      {/* <div>
        <Button style={{ border: 'none', width: '100%', backgroundColor: 'rgba(162, 212, 247, 0.5)' }} onClick={() => {
          // const textToCopy = '[' + sn + '] ' + name;
          // copy(textToCopy);
          // message.success('序列号已复制至剪贴板');
        }}>点击复制规范分支名(待定)</Button>
      </div> */}
    </div>
  );
}

/**创建需求时追加需求描述 */
export function createIssueAppendDescription(value) {
  let description = value?.description;
  if (description?.indexOf('【需求文档】') === -1 && value?.requirementDocument && value.requirementDocument.indexOf('http') !== -1) {
    description = description + `<p>【需求文档】</p>
                                   <p><a href="${value?.requirementDocument}" target="_blank" rel="noopener noreferrer">${value?.requirementDocument}</a></p>`;
  }

  if (description?.indexOf('【UI设计交付物】') === -1 && value?.uiDesignDeliverable && value.uiDesignDeliverable.indexOf('http') !== -1) {
    description = description + `<p>【UI设计交付物】</p>
                                   <p><a href="${value?.uiDesignDeliverable}" target="_blank" rel="noopener noreferrer">${value?.uiDesignDeliverable}</a></p>`;
  }

  if (description?.indexOf('【技术方案交付物】') === -1 && value?.technicalSolutionDeliverable && value.technicalSolutionDeliverable.indexOf('http') !== -1) {
    description = description + `<p>【技术方案交付物】</p>
                                   <p><a href="${value?.technicalSolutionDeliverable}" target="_blank" rel="noopener noreferrer">${value?.technicalSolutionDeliverable}</a></p>`;
  }

  return description
}

/**更新需求时追加需求描述 */
export function updateIssueAppendDescription(value, detail) {
  let description = value?.description;

  if (description?.indexOf('【需求文档】') === -1 && value?.requirementDocument && value.requirementDocument.indexOf('http') !== -1) {
    description = description + `<p>【需求文档】</p><p><a href="${value?.requirementDocument}" target="_blank" rel="noopener noreferrer">${value?.requirementDocument}</a></p>`;
  } else if (description?.indexOf('【需求文档】') !== -1 && (!value?.requirementDocument || value?.requirementDocument.length === 0)) {
    let requirementDocument = `<p><a href="${detail?.requirementDocument}" target="_blank" rel="noopener noreferrer">${detail?.requirementDocument}</a></p>`;
    description = description.replaceAll('<p>【需求文档】</p>', '')
    description = description.replaceAll(requirementDocument, '')
  } else if (description?.indexOf('【需求文档】') !== -1 && value?.requirementDocument.length > 0 && value.requirementDocument.indexOf('http') !== -1) {
    if (detail?.requirementDocument && value.requirementDocument.indexOf('http') !== -1) {
      description = description.replaceAll(detail?.requirementDocument, value?.requirementDocument)
    }
  }

  if (description?.indexOf('【UI设计交付物】') === -1 && value?.uiDesignDeliverable && value.uiDesignDeliverable.indexOf('http') !== -1) {
    description = description + `<p>【UI设计交付物】</p><p><a href="${value?.uiDesignDeliverable}" target="_blank" rel="noopener noreferrer">${value?.uiDesignDeliverable}</a></p>`;
  } else if (description?.indexOf('【UI设计交付物】') !== -1 && (!value?.uiDesignDeliverable || value?.uiDesignDeliverable.length === 0)) {
    let uiDesignDeliverable = `<p><a href="${detail?.uiDesignDeliverable}" target="_blank" rel="noopener noreferrer">${detail?.uiDesignDeliverable}</a></p>`;
    description = description.replaceAll('<p>【UI设计交付物】</p>', '')
    description = description.replaceAll(uiDesignDeliverable, '')
  } else if (description?.indexOf('【UI设计交付物】') !== -1 && value?.uiDesignDeliverable.length > 0 && value.uiDesignDeliverable.indexOf('http') !== -1) {
    if (detail?.uiDesignDeliverable && value.uiDesignDeliverable.indexOf('http') !== -1) {
      description = description.replaceAll(detail?.uiDesignDeliverable, value?.uiDesignDeliverable)
    }
  }

  if (description?.indexOf('【技术方案交付物】') === -1 && value?.technicalSolutionDeliverable && value.technicalSolutionDeliverable.indexOf('http') !== -1) {
    description = description + `<p>【技术方案交付物】</p><p><a href="${value?.technicalSolutionDeliverable}" target="_blank" rel="noopener noreferrer">${value?.technicalSolutionDeliverable}</a></p>`;
  } else if (description?.indexOf('【技术方案交付物】') !== -1 && (!value?.technicalSolutionDeliverable || value?.technicalSolutionDeliverable.length === 0)) {
    let technicalSolutionDeliverable = `<p><a href="${detail?.technicalSolutionDeliverable}" target="_blank" rel="noopener noreferrer">${detail?.technicalSolutionDeliverable}</a></p>`;
    description = description.replaceAll('<p>【技术方案交付物】</p>', '')
    description = description.replaceAll(technicalSolutionDeliverable, '')
  } else if (description?.indexOf('【技术方案交付物】') !== -1 && value?.technicalSolutionDeliverable.length > 0 && value.technicalSolutionDeliverable.indexOf('http') !== -1) {
    if (detail?.technicalSolutionDeliverable && value.technicalSolutionDeliverable.indexOf('http') !== -1) {
      description = description.replaceAll(detail?.technicalSolutionDeliverable, value?.technicalSolutionDeliverable)
    }
  }

  return description
}

// 产品需求描述默认模板
export function getProductIssueDescTemplate() {
  return `<p><strong>【背景】</strong></p><p></p><p><strong>【问题】</strong></p><p></p><p><strong>【解决方案】</strong></p><p></p>`;
}

// 业务需求描述默认模板
export function getBusinessIssueDescTemplate() {
  return    `<p><strong>【需求提出人】</strong></p><p></p><p><strong>【背景】</strong></p><p></p><p><strong>【问题】</strong></p><p></p><p><strong></p>`;
}

// 能编辑工作流的状态
export function getCanEditWorkflowStatus() {
    const canEditWorkflowStatus = ['PENDING_INTERVENTIONS', 'REQUIREMENT_QUEUE', 'UNDER_SOLUTION', 'REVIEWED', 'SERVICE_CONFIRMED', 'TO_BE_DEVELOPED'];
    return canEditWorkflowStatus;
}







